import React, { useState, useRef, useEffect } from "react";
import { createAudioProcessor } from "./audioProcessor";
import { useMicVAD } from "./vad/react/index.ts";
import { v4 as uuid } from "uuid";
import { CONFIG } from "./config";

export default function App() {
  // State Management + UI State
  const [isMuted, setIsMuted] = useState(false);
  const [isCallActive, setIsCallActive] = useState(false);
  const [audioStream, setAudioStream] = useState(null);
  const [isAiSpeaking, setIsAiSpeaking] = useState(false);
  const [logs, setLogs] = useState([]);
  const logsContainerRef = useRef(null); // Scroll to bottom
  const cancelledContextIdsRef = useRef(new Set());
  const [isLogsModalOpen, setIsLogsModalOpen] = useState(false);
  const audioElementRef = useRef(null);
  const audioChunksQueueRef = useRef([]);
  const isPlayingRef = useRef(false);
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const processorNodeRef = useRef(null);
  const gainNodeRef = useRef(null);
  const shouldProcessTranscriptionRef = useRef(false);
  const currentUtteranceEndedRef = useRef(false);
  const currentUtteranceIdRef = useRef(null);
  const latestResponseIDRef = useRef(null);
  const latestTTSRequestIDRef = useRef(null);
  const ttsRetryTimersRef = useRef(new Map());

  // MediaRecorder Refs
  // const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [isRecording, setIsRecording] = useState(false);

  // Important Object Refs
  const wsTranscription = useRef(null);
  const wsThoughtCompletion = useRef(null);
  const wsLLM = useRef(null);
  const ttsRequestChannelRef = useRef(null);
  const ttsAudioChannelRef = useRef(null);

  const pcLLM = useRef(null);
  const llmTextChannel = useRef(null);
  // Add new WebRTC connection ref for TTS
  const pcTTS = useRef(null);
  const ttsRequestChannel = useRef(null);
  const ttsAudioChannel = useRef(null);
  const wsTTS = useRef(null);
  const pendingResponsesRef = useRef(new Map()); // Map of requestId -> response data
  const thoughtCompletionResultsRef = useRef(new Map()); // Map of requestId -> completion result

  // Storage Refs
  const conversationHistoryRef = useRef([]);
  const initialTextBufferRef = useRef([]);
  const currentTextBufferRef = useRef("");
  const aiTextBufferRef = useRef("");

  // Timing Refs
  const thoughtCompletionStartTimeRef = useRef(null);
  const llmStartTimeRef = useRef(null);
  const aiShouldStartSpeakingTimeRef = useRef(null);
  const transcriptionReceivedTimeRef = useRef(null);

  // Second generation of timing refs
  const userSpeechStartTimeRef = useRef(null);
  const userSpeechEndTimeRef = useRef(null);
  const silenceStartTimeRef = useRef(null);
  const firstPunctuationTimeRef = useRef(null);
  const llmTokensCompleteTimeRef = useRef(null);
  const firstAudioChunkTimeRef = useRef(null);
  const firstAudioPlayTimeRef = useRef(null);
  const thoughtCompletionEndTimeRef = useRef(null);
  const transcriptionStartTimeRef = useRef(null);

  // Audio processing refs
  const hasStartedSpeakingRef = useRef(false);
  const isStreamingRef = useRef(false);
  const audioProcessorRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const scriptProcessorRef = useRef(null);
  const audioSenderRef = useRef(null);

  // Auto-scroll to bottom of logs
  useEffect(() => {
    if (logsContainerRef.current) {
      logsContainerRef.current.scrollTop =
        logsContainerRef.current.scrollHeight;
    }
  }, [logs]); // 'logs' is the array holding the items

  // Cleanup effect for Audio element
  useEffect(() => {
    return () => {
      if (audioElementRef.current) {
        audioElementRef.current.pause();
        audioElementRef.current.src = "";
      }
    };
  }, []);

  // Reset TTS state when starting a new response
  useEffect(() => {
    if (latestResponseIDRef.current) {
      // Reset TTS state for new response
      latestTTSRequestIDRef.current = null;
      // Clean up any pending requests from previous response
      cleanupTTSRetries();
    }
  }, [latestResponseIDRef.current]);

  const playIntroAudio = () => {
    return new Promise((resolve) => {
      const audio = new Audio("/static/audio/tony_intro.mp3");
      audio.onended = () => {
        resolve();
      };
      audio.play().catch((error) => {
        console.error("Error playing intro audio:", error);
        resolve(); // Resolve anyway to continue with the call
      });
    });
  };

  const handleStartCall = async () => {
    console.log("STARTING CALL");
    // Reset all timing refs
    userSpeechStartTimeRef.current = null;
    userSpeechEndTimeRef.current = null;
    silenceStartTimeRef.current = null;
    firstPunctuationTimeRef.current = null;
    llmTokensCompleteTimeRef.current = null;
    firstAudioChunkTimeRef.current = null;
    firstAudioPlayTimeRef.current = null;
    logMessage("Start Call");

    try {
      // Wait for websocket connections to be established
      await connectAllWebSockets();
      console.log("ALL WEBSOCKET CONNECTIONS ESTABLISHED");
      logMessage("All WebSocket Connections Established");

      // 1. Initialize AudioContext
      // DO NOT set the audio context sample rate here!
      // Just let it use the default sample rate of the device. (48kHz)
      // We can ensure higher quality resampling if we do it manually later on.
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)({});
      // {requestedSampleRate: 16000, actualSampleRate: 16000, baseLatency: 0.016, outputLatency: 0}

      // 2. Get user media stream WITHOUT explicit constraints
      // The browser overrides most of the constraints anyway...
      // just use { audio: true }
      // https://www.webrtc-developers.com/getusermedia-constraints-explained/
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Log the initial stream
      console.log("Raw audio stream obtained:", stream);
      // You can also log audio track settings
      // Log the initial stream properties
      console.log("Initial MediaStream:", {
        active: stream.active,
        id: stream.id,
        audioTracks: stream.getAudioTracks().map((track) => ({
          enabled: track.enabled,
          muted: track.muted,
          settings: track.getSettings(),
        })),
      });

      // Get sample rate from audio track settings
      const audioTrack = stream.getAudioTracks()[0];
      const settings = audioTrack.getSettings();
      console.log("MediaStream Audio Settings:", {
        sampleRate: settings.sampleRate, // Sample Rate is 48000
        channelCount: settings.channelCount,
        latency: settings.latency,
        autoGainControl: settings.autoGainControl,
        echoCancellation: settings.echoCancellation,
        noiseSuppression: settings.noiseSuppression,
      }); //{sampleRate: 48000, channelCount: 1, latency: 0.01, autoGainControl: true, echoCancellation: true, …}

      setAudioStream(stream); // Is this doing anything?
      // mediaStreamRef.current = stream;
      logMessage("User Media Stream Acquired");

      // Create audio processing pipeline
      sourceNodeRef.current =
        audioContextRef.current.createMediaStreamSource(stream);
      console.log("Source Node:", {
        numberOfInputs: sourceNodeRef.current.numberOfInputs,
        numberOfOutputs: sourceNodeRef.current.numberOfOutputs,
        channelCount: sourceNodeRef.current.channelCount,
        contextSampleRate: sourceNodeRef.current.context.sampleRate,
      }); // {numberOfInputs: 0, numberOfOutputs: 1, channelCount: 2, contextSampleRate: 16000}

      console.log("Audio connected to source node:", sourceNodeRef.current);

      // audio enters audioProcessor.js
      processorNodeRef.current = await createAudioProcessor(
        audioContextRef.current
      );

      processorNodeRef.current.port.onmessage = (event) => {
        // console.log("Direct port message received:", event.data);
        if (event.data.type === "processedAudio") {
          handleUserAudioData(event.data.audioData);
        }
      };

      // Create gain node
      // Must come before the event listener is added
      // The gain node is part of the audio processing pipeline
      // We can control the volume of the audio stream here
      // This isn't doing anything if we don't set the gain value
      // gainNodeRef.current.gain.value = 1.0; // Normal volume
      // gainNodeRef.current.gain.value = 0.5; // Half volume
      // gainNodeRef.current.gain.value = 0; // Mute
      // So this is not doing anything for us right now...
      // But it's not hurting anything either.
      gainNodeRef.current = audioContextRef.current.createGain();
      console.log("PIPELINE SET UP");

      // Add event listener for processed audio data
      // Add listener *after* all nodes are created and connected
      processorNodeRef.current.addEventListener(
        "audiodata",
        handleUserAudioData
      );
      console.log("EVENT LISTENER ADDED");

      // Connect nodes - but DON'T connect to destination
      // The destination would be something like the speakers
      // We don't want to connect to the destination here
      // We just want to send audio to the backend.
      // Audio flows from left to right
      // Microphone data → AudioWorklet processor → gain node → backend
      // by not connecting to a destination, we ensure that the audio
      // is only used for processing and transmission, not playback.
      sourceNodeRef.current.connect(processorNodeRef.current);
      processorNodeRef.current.connect(gainNodeRef.current);
      console.log("NODES CONNECTED");

      // Create a MediaStream from the processed audio for VAD
      const processedStream =
        audioContextRef.current.createMediaStreamDestination();
      gainNodeRef.current.connect(processedStream);
      console.log("PROCESSED STREAM CREATED");

      // Update the stream for VAD to use the processed audio
      setAudioStream(processedStream.stream);
      console.log("AUDIO STREAM SET");

      setIsCallActive(true);
      // console.log("CALL ACTIVE");

      // Play intro audio
      setIsAiSpeaking(true);
      await playIntroAudio();
      setIsAiSpeaking(false);

      logMessage("Call started successfully");
      console.log("CALL STARTED SUCCESSFULLY");
    } catch (error) {
      console.error("Error starting call:", error);
      logMessage(`Error starting call: ${error.message}`);

      // Detailed error logging
      if (error.name === "AbortError") {
        console.error(
          "Failed to load AudioWorklet modules. Check file paths and MIME types."
        );
      }

      // Cleanup on error
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
      }

      [ttsRequestChannelRef, ttsAudioChannelRef].forEach((channelRef) => {
        if (channelRef.current) {
          channelRef.current.close();
        }
      });

      setIsCallActive(false);
      setAudioStream(null);
    }
  };

  const vad = useMicVAD(
    {
      workletURL: "/vad.worklet.bundle.min.js",
      modelURL: "/silero_vad.onnx",
      redemptionFrames: 10, // Wait for 5 frames (≈500ms) of silence
      tentativeRedemptionFrames: 3, // fewer frames for early detection
      positiveSpeechThreshold: 0.65,
      negativeSpeechThreshold: 0.4,
      minSpeechFrames: 2,
      preSpeechPadFrames: 2,
      onSpeechStart: () => {
        if (!isCallActive) return;
        console.log("SPEECH START");
        handleSpeechStarted();
      },
      onTentativeSpeechEnd: () => {
        if (!isCallActive) return;
        console.log("TENTATIVE SPEECH END");
        handleTentativeSpeechEnd();
      },
      onDefinitiveSpeechEnd: () => {
        if (!isCallActive) return;
        console.log("DEFINITIVE SPEECH END");
        handleDefinitiveSpeechEnd();
      },
    },
    [isCallActive, isAiSpeaking, isMuted]
  );

  const handleSpeechStarted = () => {
    userSpeechStartTimeRef.current = Date.now();
    currentUtteranceIdRef.current = uuid();  // Generate new utterance ID
    console.log("SPEECH START");
    shouldProcessTranscriptionRef.current = false; // Reset when new speech starts
    currentTextBufferRef.current = ""; // Reset text buffer on new speech
    hasStartedSpeakingRef.current = true; // Set the flag to true to enable audio streaming
    currentUtteranceEndedRef.current = false;  // Reset for new utterance

    // Restart some timers
    firstAudioPlayTimeRef.current = null;
    transcriptionReceivedTimeRef.current = null
    
    // Clear all text buffers
    initialTextBufferRef.current = [];
    aiTextBufferRef.current = "";
    pendingResponsesRef.current.clear(); // Clear all pending responses

    // Tell audio processor to start sending data
    if (processorNodeRef.current) {
      console.log("Sending setActive message to processor");
      processorNodeRef.current.port.postMessage({
        type: "setActive",
        value: true,
      });
    } else {
      console.log("Processor node not available");
    }

    logMessage("User started speaking");

    // Tell audio processor to start sending data
    if (audioSenderRef.current) {
      audioSenderRef.current.port.postMessage({
        type: "setActive",
        value: true,
      });
    }

    logMessage("User started speaking");

    // Stop current audio playback
    if (audioElementRef.current) {
      audioElementRef.current.pause();
      audioElementRef.current.src = "";
    }

    // Clear audio queue
    audioChunksQueueRef.current.forEach(url => URL.revokeObjectURL(url));
    audioChunksQueueRef.current = [];
    isPlayingRef.current = false;
    setIsAiSpeaking(false);

    if (latestResponseIDRef.current) {
      cancelledContextIdsRef.current.add(latestResponseIDRef.current);
    }

    // Cancel ongoing TTS if any
    if (ttsRequestChannel.current?.readyState === "open") {
      ttsRequestChannel.current.send(
        JSON.stringify({
          type: "cancel_tts",
          contextId: latestResponseIDRef.current,
        })
      );
    }
  };

  const handleTentativeSpeechEnd = () => {
    // Don't send if not in a call
    if (!isCallActive) return;

    shouldProcessTranscriptionRef.current = true;

    shouldProcessTranscriptionRef.current = true;
    const requestId = uuid();  // Generate request ID for this tentative end
    latestResponseIDRef.current = requestId;  // Store as latest request

    // Cancel any ongoing TTS and clear audio
    if (latestResponseIDRef.current) {
      cancelledContextIdsRef.current.add(latestResponseIDRef.current);
      if (ttsRequestChannel.current?.readyState === "open") {
          ttsRequestChannel.current.send(
              JSON.stringify({
                  type: "cancel_tts",
                  contextId: latestResponseIDRef.current,
              })
          );
      }
    }

    // Stop any current audio playback
    if (audioElementRef.current) {
        audioElementRef.current.pause();
        audioElementRef.current.src = "";
    }

    // Clear previous audio buffers
    audioChunksQueueRef.current.forEach(url => URL.revokeObjectURL(url));
    audioChunksQueueRef.current = [];
    isPlayingRef.current = false;
    setIsAiSpeaking(false);

    // Clear previous text buffers
    initialTextBufferRef.current = [];
    aiTextBufferRef.current = "";
    pendingResponsesRef.current.clear();

    // Send message to backend if WebSocket is open
    if (wsTranscription.current?.readyState === WebSocket.OPEN) {
      wsTranscription.current.send(
        JSON.stringify({
          type: "tentative_speech_end",
          timestamp: Date.now(),
          requestId: requestId
        })
      );
    }

    logMessage("Tentative speech end detected");
  };

  const handleDefinitiveSpeechEnd = () => {
    // Don't listen if AI not in a call
    if (!isCallActive) return;
    console.log("SPEECH END");

    currentUtteranceEndedRef.current = true;  // Mark current utterance as ended
    userSpeechEndTimeRef.current = Date.now();
    hasStartedSpeakingRef.current = false; // Disable audio streaming

    // Add any completed LLM responses to history
    for (const [requestId, response] of pendingResponsesRef.current.entries()) {
      if (response.text) {  // Even if not complete, add what we have
          const updatedHistory = [...conversationHistoryRef.current];
          updatedHistory.push({
              role: "assistant",
              content: response.text,
              requestId: requestId
          });
          conversationHistoryRef.current = updatedHistory;
      }
    }

    // Tell audio processor to stop sending data
    if (audioSenderRef.current) {
      audioSenderRef.current.port.postMessage({
        type: "setActive",
        value: false,
      });
    }

    logMessage("User stopped speaking");

    // Tell audio processor to stop sending data
    if (processorNodeRef.current) {
      console.log("Sending setActive false to processor");
      processorNodeRef.current.port.postMessage({
        type: "setActive",
        value: false,
      });
    }

    logMessage("User stopped speaking");

    // Log duration of speech
    if (userSpeechStartTimeRef.current) {
      const speechDuration =
        userSpeechEndTimeRef.current - userSpeechStartTimeRef.current;
      logMessage(`Speech duration: ${speechDuration}ms`);
    }

    // Send a final message to indicate speech has ended
    if (wsTranscription.current?.readyState === WebSocket.OPEN) {
      wsTranscription.current.send(
        JSON.stringify({
          type: "definitive_speech_end",
          timestamp: Date.now(),
        })
      );
    }
  };

  const handleUserAudioData = (audioData) => {
    // console.log("Processing audio data of length:", audioData.length);
    if (hasStartedSpeakingRef.current) {
      sendAudioChunk(audioData);
    } else {
      console.log("Audio data received but speech not started");
    }
  };

  const sendAudioChunk = async (audioData) => {
    if (!wsTranscription.current || wsTranscription.current.readyState !== WebSocket.OPEN) {
      return;
    }
  
    try {
      // Convert Float32Array to Int16Array
      const int16Data = new Int16Array(audioData.length);
      for (let i = 0; i < audioData.length; i++) {
        // Clamp between -1 and 1, then convert to Int16
        const sample = Math.max(-1, Math.min(1, audioData[i]));
        int16Data[i] = sample < 0 ? sample * 0x8000 : sample * 0x7fff;
      }
  
      // Send the binary data
      wsTranscription.current.send(int16Data.buffer);
  
    } catch (error) {
      console.error("Error sending audio chunk:", error);
    }
  };

  const connectAllWebSockets = async () => {
    await connectTranscriptionWebSocket();
    await connectThoughtCompletionWebSocket();
    await connectLLMWebSocket();
    await connectTTSWebSocket();
  };

  const connectTranscriptionWebSocket = () => {
    return new Promise((resolve, reject) => {
      try {
          const wsUrl = CONFIG.transcriptionServiceUrl;
        
          // Add WebSocket options
          const wsOptions = {
            rejectUnauthorized: false, // Only use during development/testing
            headers: {
              'Origin': window.location.origin
            }
          };
      
          // wsTranscription.current = new WebSocket(wsUrl);
          wsTranscription.current = new WebSocket(CONFIG.transcriptionServiceUrl, [], {
            rejectUnauthorized: false  // Accept self-signed certificates
          });

          // Set binary type to arraybuffer
          wsTranscription.current.binaryType = "arraybuffer";

          const timeoutId = setTimeout(() => {
            reject(new Error("Transcription WebSocket connection timed out"));
          }, 5000);

          wsTranscription.current.onopen = () => {
            logMessage("Transcription WebSocket Connection Open");
            console.log("TRANSCRIPTION WS OPEN");

            // Send initial configuration
            wsTranscription.current.send(
              JSON.stringify({
                type: "config",
                sampleRate: 16000,
              })
            );

            clearTimeout(timeoutId);
            resolve(wsTranscription.current);
          };

          wsTranscription.current.onmessage = (event) => {
            if (typeof event.data === "string") {
              const data = JSON.parse(event.data);
              console.log("Received transcription data:", data);
              // Handle transcription results
              if (data.text) {
                handleTranscribedText({
                  text: data.text,
                  words: data.words,
                  requestId: data.requestId
                });
              }
            }
          };
        } catch (error) {
          console.error("Error creating WebSocket:", error);
          reject(error);
        }
      });
  };

  const connectThoughtCompletionWebSocket = () => {
    return new Promise((resolve, reject) => {
      wsThoughtCompletion.current = new WebSocket(
        `${CONFIG.ApiUrl}/ws/thought-completion`
      );
      // wsThoughtCompletion.current = new WebSocket('wss://13.248.140.120/ws/thought-completion', [], {
      //     headers: {
      //         Host: 'tr-backend-1948872527.us-east-1.elb.amazonaws.com'
      //     }
      // });

      // Connection timeout
      const timeoutId = setTimeout(() => {
        reject(new Error("Thought Completion WebSocket connection timed out"));
      }, 5000);

      wsThoughtCompletion.current.onopen = () => {
        logMessage("Thought Completion WebSocket Connection Open");
        clearTimeout(timeoutId);
        resolve(wsThoughtCompletion.current);
      };

      wsThoughtCompletion.current.onclose = () => {
        logMessage("Thought Completion WebSocket Connection Closed");
      };

      wsThoughtCompletion.current.onerror = (error) => {
        logMessage("Thought Completion WebSocket Connection Error");
        clearTimeout(timeoutId);
        reject(error);
      };
      wsThoughtCompletion.current.onmessage = async (event) => {
        const data = JSON.parse(event.data);
        if (data.type === "thought_completion_result") {
          handleThoughtCompletionResult(data);
        }
      };
    });
  };

  const connectLLMWebSocket = () => {
    return new Promise((resolve, reject) => {
      wsLLM.current = new WebSocket(`${CONFIG.ApiUrl}/ws/llm`);
      // wsLLM.current = new WebSocket('wss://13.248.140.120/ws/llm', [], {
      //     headers: {
      //         Host: 'tr-backend-1948872527.us-east-1.elb.amazonaws.com'
      //     }
      // });

      // Connection timeout
      const timeoutId = setTimeout(() => {
        reject(new Error("LLM WebSocket connection timed out"));
      }, 5000);

      // WebSocket Event Handlers
      wsLLM.current.onopen = async () => {
        try {
          logMessage("LLM WebSocket Connection Open");

          // Initialize WebRTC connection for LLM
          pcLLM.current = new RTCPeerConnection({
            iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
          });

          // Log connection state changes
          pcLLM.current.onconnectionstatechange = () => {
            logMessage(
              `LLM Connection State: ${pcLLM.current.connectionState}`
            );
          };

          pcLLM.current.oniceconnectionstatechange = () => {
            logMessage(
              `LLM ICE Connection State: ${pcLLM.current.iceConnectionState}`
            );
          };

          // Create data channel for LLM text
          llmTextChannel.current = pcLLM.current.createDataChannel("llm_text", {
            ordered: true,
          });

          // Data Channel Event Handlers
          llmTextChannel.current.onopen = () => {
            logMessage("LLM data channel opened");
          };

          llmTextChannel.current.onclose = () => {
            logMessage("LLM data channel closed");
          };

          llmTextChannel.current.onerror = (error) => {
            logMessage(`LLM data channel error: ${error}`);
          };

          llmTextChannel.current.onmessage = (event) => {
            try {
              const data = JSON.parse(event.data);
              switch (data.type) {
                case "llm_text_chunk":
                  handleLLMTextChunk(data);
                  break;
                case "llm_stream_end":
                  handleLLMStreamEnd(data);
                  break;
                case "llm_stream_error":
                  logMessage(`LLM stream error: ${data.error}`);
                  break;
                default:
                  console.log("Unknown LLM message type:", data.type);
              }
            } catch (error) {
              console.error("Error handling LLM message:", error);
            }
          };

          // Handle ICE candidates
          pcLLM.current.onicecandidate = async (event) => {
            if (
              event.candidate &&
              wsLLM.current?.readyState === WebSocket.OPEN
            ) {
              try {
                wsLLM.current.send(
                  JSON.stringify({
                    type: "candidate",
                    candidate: {
                      candidate: event.candidate.candidate,
                      sdpMid: event.candidate.sdpMid,
                      sdpMLineIndex: event.candidate.sdpMLineIndex,
                    },
                  })
                );
              } catch (error) {
                logMessage(`Error sending ICE candidate: ${error}`);
              }
            }
          };

          // Create and send offer
          const offer = await pcLLM.current.createOffer();
          await pcLLM.current.setLocalDescription(offer);

          if (wsLLM.current?.readyState === WebSocket.OPEN) {
            wsLLM.current.send(
              JSON.stringify({
                type: "offer",
                sdp: pcLLM.current.localDescription.sdp,
              })
            );
          } else {
            throw new Error("WebSocket not open");
          }

          clearTimeout(timeoutId);
          resolve(wsLLM.current);
        } catch (error) {
          clearTimeout(timeoutId);
          reject(error);
          cleanup();
        }
      };

      wsLLM.current.onclose = () => {
        logMessage("LLM WebSocket Connection Closed");
        cleanup();
      };

      wsLLM.current.onerror = (error) => {
        logMessage("LLM WebSocket Connection Error");
        clearTimeout(timeoutId);
        reject(error);
        cleanup();
      };

      wsLLM.current.onmessage = async (event) => {
        try {
          const data = JSON.parse(event.data);

          switch (data.type) {
            case "answer":
              const remoteDesc = new RTCSessionDescription({
                type: "answer",
                sdp: data.sdp,
              });
              await pcLLM.current.setRemoteDescription(remoteDesc);
              logMessage("LLM Remote Description Set");
              break;

            case "candidate":
              if (pcLLM.current) {
                await pcLLM.current.addIceCandidate(
                  new RTCIceCandidate(data.candidate)
                );
                logMessage("LLM ICE Candidate Added");
              }
              break;

            default:
              console.log("Unknown message type:", data.type);
          }
        } catch (error) {
          console.error("Error handling WebSocket message:", error);
          logMessage(`Error handling WebSocket message: ${error}`);
        }
      };

      // Cleanup function
      const cleanup = () => {
        if (llmTextChannel.current) {
          llmTextChannel.current.close();
          llmTextChannel.current = null;
        }
        if (pcLLM.current) {
          pcLLM.current.close();
          pcLLM.current = null;
        }
        if (wsLLM.current) {
          wsLLM.current.close();
          wsLLM.current = null;
        }
      };
    });
  };

  const connectTTSWebSocket = () => {
    return new Promise((resolve, reject) => {
      wsTTS.current = new WebSocket(`${CONFIG.ApiUrl}/ws/tts`);
      // wsTTS.current = new WebSocket('wss://13.248.140.120/ws/tts', [], {
      //     headers: {
      //         Host: 'tr-backend-1948872527.us-east-1.elb.amazonaws.com'
      //     }
      // });

      // connection timeout
      const timeoutId = setTimeout(() => {
        reject(new Error("TTS WebSocket connection timed out"));
      }, 5000);

      wsTTS.current.onopen = async () => {
        logMessage("TTS WebSocket Connection Open");

        // Initialize WebRTC connection for TTS
        pcTTS.current = new RTCPeerConnection({
          iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
        });

        // Create data channels for TTS with exact matching names
        ttsRequestChannel.current = pcTTS.current.createDataChannel(
          "tts_request",
          {
            ordered: true,
          }
        );
        ttsAudioChannel.current = pcTTS.current.createDataChannel("tts_audio", {
          ordered: true,
        });

        // Add channel state logging
        ttsRequestChannel.current.onopen = () => {
          logMessage("TTS request channel opened");
        };

        ttsRequestChannel.current.onclose = () => {
          logMessage("TTS request channel closed");
        };

        ttsRequestChannel.current.onerror = (error) => {
          logMessage("TTS request channel error: " + error);
        };

        ttsAudioChannel.current.onopen = () => {
          logMessage("TTS audio channel opened");
        };

        ttsAudioChannel.current.onclose = () => {
          logMessage("TTS audio channel closed");
        };

        ttsAudioChannel.current.onerror = (error) => {
          logMessage("TTS audio channel error: " + error);
        };

        // Set up audio channel message handler
        ttsAudioChannel.current.onmessage = async (event) => {
          const data = JSON.parse(event.data);
          switch (data.type) {
            case "tts_audio_chunk":
              await handleAudioData(data);
              break;
            case "tts_done":
              console.log(
                "TTS message playback completed. Clearing playback state."
              );
              setIsAiSpeaking(false); // Update UI
              break;
            case "tts_error":
              console.error(`TTS Error: ${data.error}`);
              break;
            default:
              console.log("Unknown TTS message type:", data.type);
          }
        };

        // Handle ICE candidates
        pcTTS.current.onicecandidate = async (event) => {
          if (event.candidate) {
            wsTTS.current?.send(
              JSON.stringify({
                type: "candidate",
                candidate: {
                  candidate: event.candidate.candidate,
                  sdpMid: event.candidate.sdpMid,
                  sdpMLineIndex: event.candidate.sdpMLineIndex,
                },
              })
            );
          }
        };

        // Track connection state changes
        pcTTS.current.onconnectionstatechange = () => {
          logMessage(`TTS Connection state: ${pcTTS.current.connectionState}`);
        };

        pcTTS.current.oniceconnectionstatechange = () => {
          logMessage(
            `TTS ICE connection state: ${pcTTS.current.iceConnectionState}`
          );
        };

        pcTTS.current.onicegatheringstatechange = () => {
          logMessage(
            `TTS ICE gathering state: ${pcTTS.current.iceGatheringState}`
          );
        };

        // Wait for channels to be established
        const channelPromise = new Promise((resolveChannel) => {
          const checkChannels = () => {
            if (
              ttsRequestChannel.current?.readyState === "open" &&
              ttsAudioChannel.current?.readyState === "open"
            ) {
              resolveChannel();
            } else {
              setTimeout(checkChannels, 100);
            }
          };
          checkChannels();
        });

        // Create and send offer after creating channels
        const offer = await pcTTS.current.createOffer();
        await pcTTS.current.setLocalDescription(offer);
        wsTTS.current?.send(
          JSON.stringify({
            type: "offer",
            sdp: pcTTS.current.localDescription.sdp,
          })
        );

        // Wait for channels to be ready
        await channelPromise;
        logMessage("All TTS channels ready");
        clearTimeout(timeoutId);
        resolve(wsTTS.current);
      };

      wsTTS.current.onclose = () => {
        logMessage("TTS WebSocket Connection Closed");
      };

      wsTTS.current.onerror = (error) => {
        logMessage("TTS WebSocket Connection Error");
        clearTimeout(timeoutId);
        reject(error);
      };

      wsTTS.current.onmessage = async (event) => {
        const data = JSON.parse(event.data);

        switch (data.type) {
          case "answer":
            const remoteDesc = new RTCSessionDescription({
              type: "answer",
              sdp: data.sdp,
            });
            await pcTTS.current.setRemoteDescription(remoteDesc);
            break;
          case "candidate":
            if (pcTTS.current) {
              await pcTTS.current.addIceCandidate(
                new RTCIceCandidate(data.candidate)
              );
            }
            break;
          default:
            console.log("Unknown message type:", data.type);
        }
      };
    });
  };

  const handleTranscribedText = async (data) => {
    console.log("handleTranscribedText", data);
  
    // Clean incoming text
    let newText = data.text.trim()
      .replace(/\.{3,}/g, '') // Remove ellipsis
      .replace(/\s+/g, ' ');  // Normalize spaces
  
    // If this is the first chunk for this utterance, reset the buffer
    if (!currentTextBufferRef.current) {
      currentTextBufferRef.current = newText;
    } else {
      // Simply concatenate with proper spacing
      currentTextBufferRef.current = `${currentTextBufferRef.current} ${newText}`;
    }
  
    // Clean up the final text
    currentTextBufferRef.current = currentTextBufferRef.current
      .replace(/\s+/g, ' ')           // Normalize spaces
      .replace(/\s+\./g, '.')         // Fix spaces before periods
      .replace(/\.\s*\./g, '.')       // Remove multiple periods
      .replace(/\s+$/g, '')           // Remove trailing spaces
      .trim();
  
    if (shouldProcessTranscriptionRef.current) {
      const updatedHistory = [...conversationHistoryRef.current];
      
      // Find the message for this utterance
      const lastMessage = updatedHistory[updatedHistory.length - 1];
      if (lastMessage?.utteranceId === currentUtteranceIdRef.current) {
          // Update existing message for this utterance
          lastMessage.content = currentTextBufferRef.current;
      } else {
          // Add new message with utterance ID
          updatedHistory.push({
              role: "user",
              content: currentTextBufferRef.current,
              utteranceId: currentUtteranceIdRef.current
          });
      }
      
      conversationHistoryRef.current = updatedHistory;
    }

    console.log("handleTranscribedText 9", data);

    console.log('conversationHistoryRef.current:', conversationHistoryRef.current);

    console.log("Updated text buffer:", currentTextBufferRef.current);

    console.log("shouldProcessTranscriptionRef:", shouldProcessTranscriptionRef, data);

    if (shouldProcessTranscriptionRef.current ) {

      // If this is transcription from a tentative speech end, start response generation
      if (data.requestId ) {  // Backend will include requestId if this is from tentativeSpeechEnd

        if (!transcriptionReceivedTimeRef.current) {
          transcriptionReceivedTimeRef.current = Date.now();
          logMessage("Transcription received");
        }

        // Reset speaking state for new response
        initialTextBufferRef.current = [];

        // Initialize pending response tracking
        pendingResponsesRef.current.set(data.requestId, {
          text: "",
          isComplete: false,
          thoughtCheckReceived: false,
          thoughtCheckPassed: false,
        });

        // Send thought completion check via WebSocket
        if (wsThoughtCompletion.current?.readyState === WebSocket.OPEN) {
          logMessage("Thought Completion Request Sent");
          thoughtCompletionStartTimeRef.current = Date.now();
          wsThoughtCompletion.current.send(
            JSON.stringify({
              type: "check_thought_completion",
              requestId: data.requestId,
              conversation_history: conversationHistoryRef.current,
            })
          );
        }

        // Send LLM request via WebRTC data channel
        if (llmTextChannel.current?.readyState === "open") {
          logMessage("LLM Generation Request Sent");
          llmTextChannel.current.send(
            JSON.stringify({
              type: "generate_response",
              requestId: data.requestId,
              conversation_history: conversationHistoryRef.current,
            })
          );
        } else {
          logMessage("ERROR: LLM data channel not ready");
        }
      }
    }
  };

  const handleThoughtCompletionResult = async (data) => {
    const requestId = data.requestId;
    const isComplete = data.is_complete;
    // Ignore if not from latest request
    if (data.requestId !== latestResponseIDRef.current) return;

    logMessage(`Thought completion result received: ${isComplete}`);
    thoughtCompletionEndTimeRef.current = Date.now();
    logDuration(
      "thought completion finished <- user stopped speaking",
      thoughtCompletionEndTimeRef.current - userSpeechEndTimeRef.current
    );
    logDuration(
      "thought completion finished <- transcription received",
      thoughtCompletionEndTimeRef.current - transcriptionReceivedTimeRef.current
    );

    logMessage(`Thought Completion Result: ${isComplete}`);
    logDuration(
      "thought completion finished <- thought completion check start",
      thoughtCompletionEndTimeRef.current -
        thoughtCompletionStartTimeRef.current
    );

    const pendingResponse = pendingResponsesRef.current.get(requestId);
    if (pendingResponse) {
      pendingResponse.thoughtCheckReceived = true;
      pendingResponse.thoughtCheckPassed = isComplete;

      if (!isComplete) {
        // Cancel LLM generation immediately
        if (llmTextChannel.current?.readyState === "open") {
          logMessage("Cancelling Response Generation");
          llmTextChannel.current.send(
            JSON.stringify({
              type: "cancel_generation",
              requestId,
            })
          );
        }
        pendingResponsesRef.current.delete(requestId);
      }
    }
  };

  const handleLLMTextChunk = async (data) => {
    const textChunk = data.text;
    logMessage(`LLM token received: "${textChunk}"`);
    const requestId = data.requestId;
    // Ignore if not from latest request
    if (data.requestId !== latestResponseIDRef.current) return;

    // Check for first punctuation
    if (
      !firstPunctuationTimeRef.current &&
      CONFIG.pausePunctuation.has(textChunk.trim())
    ) {
      firstPunctuationTimeRef.current = Date.now();
      logMessage("First punctuation received");
      logDuration(
        "first punctuation received within LLM response <- user stopped speaking",
        firstPunctuationTimeRef.current - userSpeechEndTimeRef.current
      );
      logDuration(
        "first punctuation received within LLM response <- transcription received",
        firstPunctuationTimeRef.current - transcriptionReceivedTimeRef.current
      );
      logDuration(
        "first punctuation received within LLM response <- thought completion finished",
        firstPunctuationTimeRef.current - thoughtCompletionEndTimeRef.current
      );
    }

    // Clear cancelled context IDs when starting a new response
    if (!hasStartedSpeakingRef.current) {
      cancelledContextIdsRef.current.clear();
    }

    const pendingResponse = pendingResponsesRef.current.get(requestId);
    if (!pendingResponse) return;

    // Accumulate text chunks
    pendingResponse.text += textChunk;
    if (!hasStartedSpeakingRef.current) {
      initialTextBufferRef.current.push({
        text: textChunk,
        contextId: requestId,
      });
    }

    // If we've hit definitive speech end, update conversation history with each chunk
    if (currentUtteranceEndedRef.current) {
      const updatedHistory = [...conversationHistoryRef.current];
      const lastMessage = updatedHistory[updatedHistory.length - 1];
      if (lastMessage?.role === "assistant" && lastMessage.requestId === requestId) {
          lastMessage.content = pendingResponse.text;
      } else {
          updatedHistory.push({
              role: "assistant",
              content: pendingResponse.text,
              requestId: requestId
          });
      }
      conversationHistoryRef.current = updatedHistory;
    }

    // Only proceed with TTS if thought check passed
    if (pendingResponse.thoughtCheckReceived && pendingResponse.thoughtCheckPassed) {
      if (!hasStartedSpeakingRef.current) {
          // Check if we should start TTS generation
          const shouldStartTTS = Array.from(CONFIG.pausePunctuation).some(
              (punct) => pendingResponse.text.includes(punct)
          );

          if (shouldStartTTS) {
              hasStartedSpeakingRef.current = true;
              aiShouldStartSpeakingTimeRef.current = Date.now();
              logDuration(
                  "AI should start speaking <- user stopped speaking",
                  aiShouldStartSpeakingTimeRef.current - userSpeechEndTimeRef.current
              );
              logMessage("AI should start speaking");

              // Process all collected text
              const textToProcess = initialTextBufferRef.current
                  .map((chunk) => chunk.text)
                  .join("");

              // Send to TTS through WebRTC data channel
              if (ttsRequestChannel.current?.readyState === "open") {
                  sendTTSRequest(textToProcess, latestResponseIDRef.current);
              }

              initialTextBufferRef.current = []; // Clear the buffer
              return;
          }
      } else if (CONFIG.sendOnlyCompletePhrases) {
          // Accumulate text until we hit punctuation
          initialTextBufferRef.current.push({
              text: textChunk,
              contextId: latestResponseIDRef.current,
          });

        if (CONFIG.pausePunctuation.has(textChunk.trim())) {
          // Send accumulated text to TTS
          const textToProcess = initialTextBufferRef.current
            .map((chunk) => chunk.text)
            .join("");

          if (ttsRequestChannel.current?.readyState === "open") {
            sendTTSRequest(textToProcess, latestResponseIDRef.current);
          } else {
            logMessage("TTS request channel not ready");
          }

          initialTextBufferRef.current = []; // Clear the buffer
        }
      } else {
        // Original behavior: process new chunks immediately
        if (ttsRequestChannel.current?.readyState === "open") {
          sendTTSRequest(textChunk, latestResponseIDRef.current);
        } else {
          logMessage("TTS request channel not ready");
        }
      }
    }
  };

  const handleLLMStreamEnd = (data) => {
    llmTokensCompleteTimeRef.current = Date.now();
    logMessage("LLM token stream complete");
    logDuration(
      "all LLM response tokens received <- user stopped speaking",
      llmTokensCompleteTimeRef.current - userSpeechEndTimeRef.current
    );
    logDuration(
      "all LLM response tokens received <- transcription received",
      llmTokensCompleteTimeRef.current - transcriptionReceivedTimeRef.current
    );
    const requestId = data.requestId;
    const pendingResponse = pendingResponsesRef.current.get(requestId);
    if (!pendingResponse) return;

    pendingResponse.isComplete = true;

    // Add to conversation history if we've already hit definitive speech end
    if (currentUtteranceEndedRef.current) {
      const updatedHistory = [...conversationHistoryRef.current];
      updatedHistory.push({
          role: "assistant",
          content: pendingResponse.text
      });
      conversationHistoryRef.current = updatedHistory;
  }

    // Reset state refs
    aiTextBufferRef.current = "";  // Reset AI buffer
    initialTextBufferRef.current = [];
    firstAudioPlayTimeRef.current = null;
    firstAudioChunkTimeRef.current = null;

    // Cleanup this pending response
    pendingResponsesRef.current.delete(requestId);

    logMessage("LLM Stream End");
  };

  const cancelTTSRequest = (ttsRequestId) => {
    if (ttsRequestChannel.current?.readyState === "open") {
      ttsRequestChannel.current.send(
        JSON.stringify({
          type: "cancel_tts",
          ttsRequestId: ttsRequestId
        })
      );
    }
  };

  const sendTTSRequest = (text, responseId) => {
    if (ttsRequestChannel.current?.readyState !== "open") {
      console.log("TTS channel not ready");
      return;
    }

    const ttsRequestId = uuid();

    // Send the TTS request
    ttsRequestChannel.current.send(
      JSON.stringify({
        type: "generate_tts",
        text: text,
        contextId: responseId,
        ttsRequestId: ttsRequestId
      })
    );

    // Schedule retry
    const retryTimer = setTimeout(() => {
      // Only retry if we haven't received any audio chunks yet for this response
      if (!latestTTSRequestIDRef.current || 
          (responseId === latestResponseIDRef.current && 
           ttsRequestId !== latestTTSRequestIDRef.current)) {
        console.log(`Retrying TTS request for response ${responseId}`);
        sendTTSRequest(text, responseId);
      }
    }, 3000);

    // Store the timer reference
    ttsRetryTimersRef.current.set(ttsRequestId, retryTimer);
  };

  const cleanupTTSRetries = (excludeRequestId = null) => {
    // Clear all retry timers except for the excluded request ID
    for (const [ttsRequestId, timer] of ttsRetryTimersRef.current.entries()) {
      if (ttsRequestId !== excludeRequestId) {
        clearTimeout(timer);
        cancelTTSRequest(ttsRequestId);
        ttsRetryTimersRef.current.delete(ttsRequestId);
      }
    }
  };

  const handleAudioData = async (data) => {
    try {
      // Ignore if not from latest request
      if (data.contextId !== latestResponseIDRef.current) return;

      // Set the latestTTSRequestID if this is the first audio chunk we've received for the current response
      if (!latestTTSRequestIDRef.current && data.contextId === latestResponseIDRef.current) {
        latestTTSRequestIDRef.current = data.ttsRequestId;
        cleanupTTSRetries(data.ttsRequestId);
      }

      // Ignore if not from the first successful TTS request for this response
      if (data.ttsRequestId !== latestTTSRequestIDRef.current) {
        return;
      }

      if (cancelledContextIdsRef.current.has(data.contextId)) {
        return;
      }

      // Create audio element if not exists
      if (!audioElementRef.current) {
        audioElementRef.current = new Audio();
        audioElementRef.current.onplay = () => {
          setIsAiSpeaking(true);
          // Send audio to processor for echo cancellation
          if (processorNodeRef.current) {
            processorNodeRef.current.port.postMessage({
              type: "aiAudio",
              audio: data.audio,
            });
          }
          if (!firstAudioPlayTimeRef.current) {
            firstAudioPlayTimeRef.current = Date.now();
            logMessage("First AI speech audio chunk received");
  
            // Calculate durations correctly
            const userSpeechEnd = userSpeechEndTimeRef.current || 0;
            const transcriptionReceived = transcriptionReceivedTimeRef.current || 0;
            const thoughtCompletionEnd = thoughtCompletionEndTimeRef.current || 0;
            const aiShouldStartSpeaking = aiShouldStartSpeakingTimeRef.current || 0;
            const currentTime = firstAudioPlayTimeRef.current;
  
            // Log the durations
            if (userSpeechEnd) {
              logDuration(
                "first audio chunk received <- user stopped speaking",
                currentTime - userSpeechEnd
              );
            }
            if (transcriptionReceived) {
              logDuration(
                "first audio chunk received <- transcription received",
                currentTime - transcriptionReceived
              );
            }
            if (thoughtCompletionEnd) {
              logDuration(
                "first audio chunk received <- thought completion finished",
                currentTime - thoughtCompletionEnd
              );
            }
            if (aiShouldStartSpeaking) {
              logDuration(
                "first audio chunk received <- AI should start speaking",
                currentTime - aiShouldStartSpeaking
              );
            }
          }
        };
        audioElementRef.current.onended = () => {
          // Play next chunk if available
          playNextChunk();
        };
      }

      // Convert base64 to blob and create object URL
      const audioData = atob(data.audio);
      const audioArray = new Uint8Array(audioData.length);
      for (let i = 0; i < audioArray.length; i++) {
        audioArray[i] = audioData.charCodeAt(i);
      }
      const blob = new Blob([audioArray], { type: "audio/mp3" });
      const url = URL.createObjectURL(blob);

      // Add to queue
      audioChunksQueueRef.current.push(url);

      // Start playing if not already playing
      if (currentUtteranceEndedRef.current && !isPlayingRef.current) {
        playNextChunk();
      }
    } catch (error) {
      console.error("Error handling audio data:", error);
      logMessage(`Audio Processing Error: ${error.message}`);
    }
  };

  // Add new function to play next chunk
  const playNextChunk = async () => {
    console.log('playNextChunk conversationHistoryRef.current:', conversationHistoryRef.current);
    if (audioChunksQueueRef.current.length === 0) {
      isPlayingRef.current = false;
      setIsAiSpeaking(false);
      return;
    }

    isPlayingRef.current = true;
    const currentUrl = audioChunksQueueRef.current.shift();

    try {
      audioElementRef.current.src = currentUrl;
      await audioElementRef.current.play();

      // Clean up previous URL after it starts playing
      URL.revokeObjectURL(currentUrl);
    } catch (error) {
      console.error("Error playing audio chunk:", error);
      playNextChunk(); // Try next chunk if current fails
    }
  };

  const handleEndCall = async () => {
    logMessage("Ending Call");

    try {
      // Stop audio stream
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
        setAudioStream(null);
      }

      // Reset state
      setIsCallActive(false);
      setIsAiSpeaking(false);
      hasStartedSpeakingRef.current = false;
      initialTextBufferRef.current = [];
      // Clean up audio queue
      audioChunksQueueRef.current.forEach((url) => URL.revokeObjectURL(url));
      audioChunksQueueRef.current = [];
      isPlayingRef.current = false;
      // Clean up audio element
      if (audioElementRef.current) {
        audioElementRef.current.pause();
        audioElementRef.current.src = "";
      }
      // Clean up audio processing
      if (sourceNodeRef.current) {
        sourceNodeRef.current.disconnect();
        sourceNodeRef.current = null;
      }
      if (processorNodeRef.current) {
        processorNodeRef.current.disconnect();
        processorNodeRef.current = null;
      }
      if (gainNodeRef.current) {
        gainNodeRef.current.disconnect();
        gainNodeRef.current = null;
      }
      if (audioContextRef.current) {
        await audioContextRef.current.close();
        audioContextRef.current = null;
      }
    } catch (error) {
      console.error("Error ending call:", error);
      logMessage(`Error ending call: ${error.message}`);
    }
  };

  const logMessage = (message) => {
    const log = { type: "event", time: new Date(), message };
    setLogs((prevLogs) => [...prevLogs, log]);
  };

  const logDuration = (message, duration) => {
    const log = {
      message: message,
      type: "duration",
      time: new Date(),
      duration,
    };
    setLogs((prevLogs) => [...prevLogs, log]);
  };

  const handleMuteToggle = () => {
    console.log("toggled mute:", isMuted);
    if (audioStream) {
      audioStream.getAudioTracks().forEach((track) => {
        track.enabled = isMuted; // Toggle the current state
      });
      setIsMuted(!isMuted);
    }
  };

  const buttonStyle = {
    backgroundColor: "rgb(102, 51, 238)",
    color: "white",
    border: "2px solid rgb(102, 51, 238)",
    borderRadius: "8px",
    padding: "8px 20px",
    fontSize: "16px",
    outline: "none",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 8px",
    transition: "0.3s",
    opacity: "1",
    cursor: "pointer",
  };

  const containerStyle = {
    height: "100vh",
    backgroundColor: "#010810",
    padding: "20px", // Add this line
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: "20px",
    boxSizing: "border-box", // Add this to include padding in height calculation
  };

  const statusBoxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px",
    border: "1px solid rgb(102, 51, 238)",
    borderRadius: "8px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 8px",
    width: "250px",
    height: "40px",
  };

  const muteButtonStyle = {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    backgroundColor: isMuted ? "rgb(240, 62, 62)" : "rgb(76, 217, 100)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    outline: "none",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 8px",
    transition: "0.3s",
    opacity: "1",
    cursor: "pointer",
    marginTop: "20px",
    marginBottom: "20px",
  };

  const modalOverlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: "#010810",
    width: "100%",
    height: "100%",
    padding: "20px",
    boxSizing: "border-box",
    position: "relative",
    overflowY: "hidden",
    display: "flex",
    flexDirection: "column",
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "20px",
    right: "20px",
    backgroundColor: "transparent",
    border: "none",
    color: "white",
    fontSize: "24px",
    cursor: "pointer",
    zIndex: 1001,
  };

  const openLogsButtonStyle = {
    position: "fixed",
    top: "20px",
    right: "20px",
    backgroundColor: "rgb(102, 51, 238)",
    color: "white",
    border: "none",
    borderRadius: "8px",
    padding: "8px 20px",
    cursor: "pointer",
    zIndex: 999,
  };

  return (
    <>
      {isLogsModalOpen && (
        <div style={modalOverlayStyle}>
          <div style={modalContentStyle}>
            <button
              style={closeButtonStyle}
              onClick={() => setIsLogsModalOpen(false)}
            >
              ×
            </button>
            <h1 style={{ color: "white", marginBottom: "20px" }}>
              Detailed Logs
            </h1>
            <div
              ref={logsContainerRef}
              style={{
                flex: 1,
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              {logs.map((log, index) => (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    boxSizing: "border-box",
                  }}
                >
                  {log.type === "event" ? (
                    <div
                      style={{
                        backgroundColor: "#333",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "5px 10px",
                      }}
                    >
                      <p>{log.message}</p>
                      <p style={{ fontWeight: "bold" }}>
                        {log.time
                          .toLocaleTimeString()
                          .replace(/AM|PM/, "")
                          .trim() +
                          "." +
                          log.time
                            .getMilliseconds()
                            .toString()
                            .padStart(3, "0")}
                      </p>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#555",
                        padding: "5px 10px",
                      }}
                    >
                      <p>{log.message}</p>
                      <p>&nbsp;-&nbsp;</p>
                      <p>{log.duration} ms</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Logs Button */}
      <button
        style={openLogsButtonStyle}
        onClick={() => setIsLogsModalOpen(true)}
      >
        Show Logs
      </button>

      <div style={containerStyle}>
        <div
          style={{
            height: "30vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {/* <h1 style={{ 
            color: 'white', 
            margin: '0 0 10px 0' 
          }}>
            Conversation History
          </h1>
          <div style={{
            flex: 1,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '2px'
          }}>
            {conversationHistoryRef.current.map((message, index) => (
              <div
              key={index}
              style={{
                width: '100%',
                backgroundColor: '#333',
                padding: '15px',
                color: 'white',
                boxSizing: 'border-box',
                wordWrap: 'break-word'
              }}
            >
              <span style={{ 
                fontWeight: 'bold', 
                marginRight: '8px' 
              }}>
                {message.role.toUpperCase()}:
              </span>
              {message.content}
            </div>
            ))}
          </div> */}
        </div>
        <div
          style={{
            height: "70vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          {!isCallActive ? (
            <button style={buttonStyle} onClick={handleStartCall}>
              Talk to {CONFIG.persona}
            </button>
          ) : (
            <>
              <div style={statusBoxStyle}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    color: "white",
                  }}
                >
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: isAiSpeaking ? "#4CAF50" : "#f03e3e",
                      borderRadius: "4px",
                      marginRight: "10px",
                    }}
                  />
                  <span>
                    {isAiSpeaking
                      ? `${CONFIG.persona} is speaking`
                      : `${CONFIG.persona} is listening`}
                  </span>
                </div>
              </div>
              <button style={muteButtonStyle} onClick={handleMuteToggle}>
                {isMuted ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="78"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ color: "white", strokeWidth: 2 }}
                  >
                    <line x1="2" x2="22" y1="2" y2="22"></line>
                    <path d="M18.89 13.23A7.12 7.12 0 0 0 19 12v-2"></path>
                    <path d="M5 10v2a7 7 0 0 0 12 5"></path>
                    <path d="M15 9.34V5a3 3 0 0 0-5.68-1.33"></path>
                    <path d="M9 9v3a3 3 0 0 0 5.12 2.12"></path>
                    <line x1="12" x2="12" y1="19" y2="22"></line>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="78"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ color: "white", strokeWidth: 2 }}
                  >
                    <path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z"></path>
                    <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                    <line x1="12" x2="12" y1="19" y2="22"></line>
                  </svg>
                )}
              </button>
              <button style={buttonStyle} onClick={handleEndCall}>
                End Call
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
