export const CONFIG = {
  sendOnlyCompletePhrases: true,  // Set to false for original behavior
  initialChunkThreshold: 20,
  pausePunctuation: new Set([".", "?", "!", ":", ";"]),
  persona: 'Tony R',
  httpUrl: 'http://localhost:8000',
  // ApiUrl: 'ws://localhost:8000',
  // ApiUrl: 'ws://54.147.11.26:8000'
  // ApiUrl: 'ws://54.204.121.217:8000'
//   ApiUrl: 'wss://tr-backend-1948872527.us-east-1.elb.amazonaws.com'
//   ApiUrl: 'wss://tr-backend.jennai.com',
//   ApiURL: 'wss://trw-backend-1505421157.us-west-1.elb.amazonaws.com'
//   ApiUrl: 'wss://trw-backend.jennai.com',
  ApiUrl: 'wss://tre-backend2.jennai.com',
  // ApiUrl: 'wss://13.248.140.120'
  // ApiUrl: 'ws://13.52.77.250:8000'
  // transcriptionServiceUrl: 'ws://localhost:7007'
  // transcriptionServiceUrl: 'ws://195.26.232.153:44209'
  transcriptionServiceUrl: 'wss://tre-backend2.jennai.com/ws/transcription'
  // transcriptionServiceUrl: 'wss://195.26.232.153:44209/ws'
  // transcriptionServiceUrl: 'wss://qplj84u16i9ekd-5000.proxy.runpod.net'
  // transcriptionServiceUrl: 'wss://qplj84u16i9ekd-5000.proxy.runpod.net/ws'
};