export async function createAudioProcessor(audioContext) {
  await audioContext.audioWorklet.addModule("/echoProcessor.js");

  const processorNode = new AudioWorkletNode(audioContext, "echo-processor", {
    numberOfInputs: 1,
    numberOfOutputs: 1,
    channelCount: 1,
    processorOptions: {
      sampleRate: audioContext.sampleRate,
    },
  });

  console.log("Processor Node Creation:", {
    contextSampleRate: audioContext.sampleRate,
    nodeChannelCount: processorNode.channelCount,
    numberOfInputs: processorNode.numberOfInputs,
    numberOfOutputs: processorNode.numberOfOutputs,
  }); // {contextSampleRate: 16000, nodeChannelCount: 1, numberOfInputs: 1, numberOfOutputs: 1}

  // Add message handler directly on the port
  processorNode.port.onmessage = (event) => {
    console.log("Received message from AudioWorklet:", event.data);
    if (event.data.type === "processedAudio") {
      const audioData = event.data.audioData;
      /////////////////////
      console.log("Processed Audio Data:", {
        sampleCount: audioData.length,
        maxValue: Math.max(...audioData),
        minValue: Math.min(...audioData),
        avgValue: audioData.reduce((a, b) => a + b, 0) / audioData.length,
      });
      /////////////////////
      // Create and dispatch the custom event
      const customEvent = new CustomEvent("audiodata", {
        detail: audioData,
      });
      processorNode.dispatchEvent(customEvent);
    }
  };

  return processorNode;
}
